import {
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query'
import { getAuthToken, useAuth } from 'hooks/useAuth'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { createQueryString } from 'utils'
import { Comment, Event, Image, Item, Poll, PollMapped, Registry, Task, User } from './Types'

const server = process.env.REACT_APP_SERVER

type Filters = {
  is_completed?: boolean
  state?: 'COMPLETED' | 'PENDING'
  limit?: string
  offset?: string
}

type UseGetItemParams<TData> = {
  route: string
  queryKey: Array<string | number | Record<string, string>>
  enabled?: boolean
  select?: (data: TData) => TData
  queryFn?: () => Promise<TData>
}
const useGetItem = <TData,>({
  route,
  queryKey,
  enabled,
  select,
  queryFn,
}: UseGetItemParams<TData>): UseQueryResult<Item<TData>> => {
  const { user } = useAuth()
  const query = useQuery({
    queryKey,
    queryFn:
      queryFn ??
      (async () => {
        const response = await fetch(`${server}${route}`, {
          headers: {
            Authorization: getAuthToken(user?.id),
          },
        })

        return await response.json()
      }),
    enabled,
    select: (data) => select?.(data) ?? data,
  }) 


  return {
    ...query,
    data: query.data ?? {},
  }
}

type UseGetItemsParams<TData, TDataMapped = TData> = {
  route: string
  filters?: Filters
  queryKey: Array<string | number | Record<string, string | boolean | undefined> | undefined>
  dataKey: string
  select?: (data: TData) => TDataMapped
  enabled?: boolean
}
const useGetItems = <TData, TDataMapped = TData>({
  route,
  filters,
  queryKey,
  dataKey,
  select,
  enabled,
}: UseGetItemsParams<TData, TDataMapped>): UseInfiniteQueryResult<TDataMapped> => {
  const { user } = useAuth()

  const fetchItems = async ({ pageParam = 0 }: { pageParam?: number }) => {
    const queryString = createQueryString({
      ...filters,
      offset: pageParam,
    })
    const response = await fetch(`${server}${route}` + queryString, {
      headers: {
        Authorization: getAuthToken(user?.id),
      },
    })
    return await response.json()
  }

  const infiniteQuery = useInfiniteQuery({
    queryKey,
    queryFn: fetchItems,
    getNextPageParam: (page) => {
      const next = page._links.next?.href
      return !next ? null : parseInt(new URLSearchParams(new URL(next).search).get('offset') || '0')
    },
    enabled,
    initialPageParam: 0,
  })
  const flatData =
    infiniteQuery.data?.pages.flatMap(({ _embedded }) => _embedded?.[dataKey] || []) || []
  const formattedData = (!select ? flatData : select(flatData as TData)) as TDataMapped

  return {
    ...infiniteQuery,
    data: formattedData,
  } as UseInfiniteQueryResult<TDataMapped>
}

export const useGetEvent = (enabled = true) => {
  const { eventId } = useParams()

  return useGetItem<Event>({
    route: `/events/${eventId}`,
    enabled,
    queryKey: ['events', eventId as string],
  })
}

export const useGetAllEventRsvps = () => {
  const { user } = useAuth()
  const { eventId } = useParams()
  const { data, isLoading, error, fetchNextPage, isFetchingNextPage, hasNextPage } =
    // @ts-expect-error blah
    useInfiniteQuery({
      queryKey: ['rsvps', eventId],
      queryFn: async ({ pageParam = 0 }) => {
        const response = await fetch(`${server}/events/${eventId}/rsvps?offset=${pageParam}`, {
          headers: {
            Authorization: getAuthToken(user?.id),
          },
        })
        return await response.json()
      },
      getNextPageParam: (lastPage) => {
        const next = lastPage._links.next?.href
        if (!next) return null
        return new URLSearchParams(new URL(next).search).get('offset')
      },
      initialPageParam: 0,
    })

  useEffect(() => {
    if (hasNextPage && !isFetchingNextPage) fetchNextPage()
  }, [hasNextPage, isFetchingNextPage, fetchNextPage])

  // @ts-expect-error blah
  const eventRsvpsData = data?.pages?.[0]?.page?.count
    ? // @ts-expect-error blah
      data?.pages.flatMap(({ _embedded }) => _embedded?.rsvpDetails)
    : []

  return {
    data: eventRsvpsData,
    isLoading,
    error,
    // @ts-expect-error blah
    count: data?.pages?.[0]?.page?.count,
  }
}

export const useGetComments = () => {
  const { eventId } = useParams()

  return useGetItems<Comment>({
    route: `/events/${eventId}/comments`,
    queryKey: ['comments', eventId],
    dataKey: 'commentDetails',
  })
}

export const useGetHostedEvents = (filters: Filters, enabled = true) => {
  const { user } = useAuth()

  return useGetItems<Event[]>({
    route: `/users/${user.id}/hosted_events`,
    queryKey: ['events'],
    dataKey: 'eventDetails',
    filters,
    enabled,
  })
}

export const useGetRsvpEvents = (filters: Filters, enabled = true) => {
  const { user } = useAuth()

  return useGetItems<Event[]>({
    route: `/users/${user.id}/rsvp_events`,
    queryKey: ['rsvp-events'],
    dataKey: 'eventDetails',
    filters,
    enabled,
  })
}

export const useGetUser = (userId: string) => {
  const { user } = useAuth()

  return useGetItem<User>({
    route: `/users/${userId ?? user.id}`,
    queryKey: ['users', userId ?? user.id],
  })
}

export const useGetRegistry = () => {
  const { user } = useAuth()
  const { eventId } = useParams()
  const queryClient = useQueryClient()

  return useGetItem<Registry>({
    route: `/events/${eventId}/registry`,
    queryKey: ['registry', eventId as string],
    queryFn: async () => {
      try {
        const response = await fetch(`${server}/events/${eventId}/registry`, {
          headers: {
            Authorization: getAuthToken(user.id),
          },
        })

        return await response.json()
      } catch (error) {
        // post (BE creates unusable format) -> patch -> refetch registry
        await fetch(`${server}/events/${eventId}/registry`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: getAuthToken(user.id),
          },
          body: JSON.stringify({ entries: [{}] }),
        })

        await fetch(`${server}/events/${eventId}/registry`, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Authorization: getAuthToken(user.id),
          },
          body: JSON.stringify({ entries: [{}] }),
        })

        queryClient.refetchQueries({ queryKey: ['registry', eventId] })
      }
    },
    enabled: !!user,
  })
}

// TODO: use infinite query to fetch all tasks
export const useGetTasks = (filters: Filters = {}) => {
  const { is_completed, state } = filters
  const { user } = useAuth()
  const { eventId } = useParams()

  return useGetItems<Task>({
    route: `/events/${eventId}/tasks`,
    queryKey: [
      'tasks',
      {
        eventId,
        isCompleted: is_completed,
        state,
      },
    ],
    dataKey: 'taskDetails',
    enabled: !!user,
    filters,
  })
}

// TODO: add pagination
export const useGetPolls = () => {
  const { eventId } = useParams()
  const { user } = useAuth()

  return useGetItems<Poll[], PollMapped[]>({
    route: `/events/${eventId}/polls`,
    queryKey: ['polls', eventId],
    dataKey: 'pollDetails',
    select: (pollDetails) => {
      return pollDetails.map((poll) => ({
        ...poll,
        hasVoted: (() => {
          for (const entry of poll.entries) {
            for (const response of entry.responses || []) {
              if (response.user === user.id) {
                return true
              }
            }
          }
          return false
        })(),
      }))
    },
    enabled: !!user,
  })
}

export const useGetEventUsers = () => {
  const { eventId } = useParams()

  return useGetItems<User[], Record<string, User>>({
    route: `/events/${eventId}/users`,
    queryKey: ['users', eventId],
    dataKey: 'listUserDetails',
    filters: { limit: '100', offset: '0' },
    select: (users) => Object.fromEntries(users?.map((user) => [user.id, user])),
  })
}

export const useGetImage = (enabled = true, event_id = null) => {
  const { eventId: id } = useParams()
  const eventId = event_id ?? id
  const { user } = useAuth()
  const filters = { type: eventId ? 'EVENT' : 'PROFILE', linked_id: eventId ?? user.id }
  const queryString = createQueryString({ ...filters })

  return useGetItem<Image>({
    route: `/images` + queryString,
    enabled,
    queryKey: ['images', filters],
  })
}
